import { ChangeEvent, useEffect, useState, useCallback, FormEvent } from 'react';

import styled from 'styled-components';

import {
  OTP_LENGTH,
  OTP_PATTERN,
  OTP_VALIDATION_LIMIT,
  RESEND_LIMIT,
  RESEND_TIME,
} from './constants';

import {
  Banner,
  BannerHeading,
  BannerIcon,
  BannerParagraph,
  Button,
  Form,
  FormItem,
  Grid,
  Spacer,
  TextInput,
  Typography,
  getSpace,
  useToast,
} from '@aircall/tractor-v2';
import { DynamicAssetComponent } from '@components/DynamicAssetComponent';
import { ExternalLink } from '@components/Link';
import {
  LayoutWrapper,
  FormWrapper,
  LeftWrapper,
  RightWrapper,
  LoadingContainer,
  Signup,
} from '@components/LoginLayout/Login.styles';
import { ResellerConfig } from '@config/resellers.config';
import { BACKEND_ERRORS_TRANSLATION_KEYS } from '@constants/errors.constants';
import { Loading } from '@dashboard/library';
import { clearCookies } from '@helpers/authentication.helpers';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import noop from 'lodash-es/noop';
import { Form as RFForm } from 'react-final-form';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router';

const ImageWrapper = styled.div`
  max-width: 512px;

  > svg {
    max-width: 100%;
  }

  > div:first-child svg {
    margin-bottom: ${getSpace('l')};
  }
`;

const MFA_ATTEMPT_RETRYABLE = ResellerConfig.getAssetPath(`mfa_attempt_retryable`);
const MFA_ATTEMPT_LAST = ResellerConfig.getAssetPath(`mfa_attempt_last`);
const MFA_ATTEMPT_FRESH = ResellerConfig.getAssetPath(`mfa_attempt_fresh`);

const getImageName = (resendButtonCount: number) => {
  switch (resendButtonCount) {
    case 1:
    case 2:
      return MFA_ATTEMPT_RETRYABLE;
    case 3:
      return MFA_ATTEMPT_LAST;
    default:
      return MFA_ATTEMPT_FRESH;
  }
};

export function MFAForm() {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>('');
  const [shouldDisableValidation, setShouldDisableValidation] = useState<boolean>(true);
  const [resendButtonCount, setResendButtonCount] = useState(0);
  const [isResendButtonActive, setIsResendButtonActive] = useState<boolean>(false);
  const [codeValidationCount, setCodeValidationCount] = useState(0);
  const validationAttemptsExhausted = codeValidationCount === OTP_VALIDATION_LIMIT;
  const retryAndValidationAttemptsExhausted =
    resendButtonCount === RESEND_LIMIT && validationAttemptsExhausted;

  const {
    authState: { signingIn, signedInError, email, sessionTokenForMFA },
    actions: {
      resendMultiFactorAuthCode,
      validateMultiFactorAuthCode,
      setMultiFactorAuthParams,
      setSignInError,
    },
  } = useAuthenticationState();
  const [isLoading, setIsLoading] = useState(signingIn);
  const navigate = useNavigate();

  function resetToLoginPage() {
    setMultiFactorAuthParams(null, null, false);
    navigate('/login');
    clearCookies();
  }

  const handleLoginRedirect = useCallback(() => {
    resetToLoginPage();
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim();
    if (OTP_PATTERN.test(val)) {
      setCode(val);
      if (signedInError) {
        setSignInError(null);
      }
    }
    if (val.length === OTP_LENGTH) {
      setShouldDisableValidation(false);
      setIsLoading(false);
    } else {
      setShouldDisableValidation(true);
    }
    if (val.length === 0) {
      setCode('');
    }
  };

  const handleResend = useCallback(async () => {
    setIsResendButtonActive(false);
    setCode('');
    const resentCode = await resendMultiFactorAuthCode(email as string);
    if (!resentCode) {
      return;
    }
    setResendButtonCount((prevCount) => prevCount + 1);
    setCodeValidationCount(0);
    setSignInError(null);
  }, [email, resendMultiFactorAuthCode]);

  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      validateMultiFactorAuthCode({ code, email, session: sessionTokenForMFA });
      setShouldDisableValidation(true);
      setCodeValidationCount((prevCount) => prevCount + 1);
    },
    [code, email, sessionTokenForMFA, validateMultiFactorAuthCode]
  );

  const getDescription = () => {
    switch (resendButtonCount) {
      case 1:
      case 2:
        return t('login.mfa.form.resend.attempt_1');
      default:
        return t('login.mfa.description');
    }
  };

  const toast = useToast();

  function handleMFAError(errorMessage: string) {
    if (errorMessage.includes('invalidOTP') || errorMessage.includes('expiredOTP')) {
      return;
    }

    setSignInError(null);

    if (errorMessage.includes('unauthorized') || errorMessage.includes('invalidRequestBody')) {
      toast.showToast({
        variant: 'critical',
        message: t(BACKEND_ERRORS_TRANSLATION_KEYS.MFA_SESSION_EXPIRED),
      });
      resetToLoginPage();
      return;
    }

    if (errorMessage.includes('requestTooEarly') || errorMessage.includes('tooManyRequests')) {
      toast.showToast({
        variant: 'critical',
        message: t(BACKEND_ERRORS_TRANSLATION_KEYS.TOO_MANY_REQUESTS),
      });
    }
    if (errorMessage.includes('validationErrorRequests')) {
      toast.showToast({
        variant: 'critical',
        message: t(BACKEND_ERRORS_TRANSLATION_KEYS.VALIDATION_LIMIT_EXCEED),
      });
      resetToLoginPage();
    }
  }

  useEffect(() => {
    setIsLoading(signingIn);
    if (signedInError) {
      handleMFAError(signedInError);
    }
    if (resendButtonCount < RESEND_LIMIT) {
      const interval = setTimeout(() => {
        setIsResendButtonActive(true);
      }, RESEND_TIME);

      return () => clearTimeout(interval);
    }
    return noop();
  }, [resendButtonCount, setIsResendButtonActive, signingIn, t, signedInError]);

  return (
    <LayoutWrapper w='100%' h='100%' data-test='mfa-layout'>
      <LeftWrapper
        backgroundColor='neutral-100'
        data-test='mfa-layout-left-wrapper'
        padding='10% 10% 20%'
        display='block'
      >
        <ImageWrapper data-test='layout-image'>
          <DynamicAssetComponent
            name={ResellerConfig.getAssetPath('logo')}
            height={44}
            width={152}
          />
        </ImageWrapper>
        <ImageWrapper style={{ padding: '0 15% 0' }}>
          <DynamicAssetComponent name={getImageName(resendButtonCount)} />
        </ImageWrapper>
      </LeftWrapper>
      <RightWrapper
        flex='1 1 50%'
        alignItems='flex-start'
        justifyContent='center'
        padding='10% 0% 20%'
        data-test='mfa-layout-right-wrapper'
      >
        <FormWrapper>
          {isLoading && (
            <LoadingContainer>
              <Loading data-test='mfa-page-loading' />
            </LoadingContainer>
          )}
          <Spacer data-test='mfa-form' direction='vertical' space='m' fluid>
            <Typography
              variant='headingMediumL'
              textAlign='center'
              alignSelf='stretch'
              data-test='mfa-title'
            >
              {resendButtonCount < 1 ? t('login.mfa.title') : t('login.mfa.form.resend.title')}
            </Typography>

            {resendButtonCount < RESEND_LIMIT ? (
              <Typography variant='bodyRegularM' data-test='mfa-step-description'>
                {getDescription()}
              </Typography>
            ) : (
              <Typography variant='bodyRegularM' data-test='mfa-step-description'>
                <Trans i18nKey='login.mfa.form.resend.attempt_3'>
                  <strong />
                </Trans>
              </Typography>
            )}

            {resendButtonCount === 2 && (
              <Typography variant='bodyRegularM' alignSelf='stretch' data-test='resend-suggestions'>
                <Trans i18nKey='login.mfa.form.resend.attempt_2'>
                  <strong />
                </Trans>
              </Typography>
            )}

            <Spacer direction='vertical' space='xl' alignItems='stretch' fluid>
              <RFForm
                onSubmit={onSubmit}
                render={() => (
                  <Spacer fluid direction='vertical' space='m'>
                    <Form onSubmit={onSubmit} style={{ width: '100%' }}>
                      <Grid columnGap='3' rowGap='2' alignItems='flex-end'>
                        <FormItem
                          gridColumn='1 / span 2'
                          data-test='mfa-code'
                          label={t('login.mfa.form.code.label')}
                          name='code'
                          validationStatus={signedInError ? 'error' : 'success'}
                        >
                          {/* OTP */}
                          <TextInput
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            placeholder=''
                            size='regular'
                            variant='input'
                            name='code'
                            value={code}
                            maxLength={OTP_LENGTH}
                            data-test='enter-code-input'
                            disabled={validationAttemptsExhausted}
                          />
                        </FormItem>
                        <FormItem gridColumn='3'>
                          <Button
                            data-test='validate-code-button'
                            disabled={
                              shouldDisableValidation ||
                              isLoading ||
                              validationAttemptsExhausted ||
                              retryAndValidationAttemptsExhausted
                            }
                            type='submit'
                            size='regular'
                          >
                            {t('login.mfa.form.submit.label')}
                          </Button>
                        </FormItem>
                        <FormItem gridColumn='1 / span 2'>
                          {signedInError && !retryAndValidationAttemptsExhausted && (
                            <Typography
                              variant='supportingMediumXS'
                              color='critical-800'
                              data-test='invalid-code-error'
                            >
                              {t('login.mfa.invalid_code')}
                            </Typography>
                          )}
                        </FormItem>
                      </Grid>
                    </Form>
                    {resendButtonCount < RESEND_LIMIT && (
                      <Spacer
                        space='xxs'
                        alignItems='left'
                        direction='vertical'
                        data-test='resend-mfa-code'
                      >
                        <Button
                          mode='outline'
                          variant='primary'
                          onClick={handleResend}
                          disabled={!(isResendButtonActive && resendButtonCount < RESEND_LIMIT)}
                          data-test='resend-code-button'
                          size='small'
                        >
                          {t('login.mfa.form.resend.label')}
                        </Button>
                        {!isResendButtonActive && (
                          <Typography
                            variant='supportingMediumXS'
                            textAlign='center'
                            alignSelf='stretch'
                            data-test='resend-button-limit'
                            color='neutral-400'
                          >
                            {t('login.mfa.form.resend.limit')}
                          </Typography>
                        )}
                      </Spacer>
                    )}
                    {retryAndValidationAttemptsExhausted && (
                      <Spacer
                        space='s'
                        alignItems='flex-end'
                        direction='vertical'
                        data-test='help-banner'
                      >
                        <Banner variant='warning' borderRadius='8px'>
                          <>
                            <BannerIcon data-test='help-banner-icon' />
                            <BannerHeading>{t('login.mfa.form.help_title')}</BannerHeading>
                            <BannerParagraph>{t('login.mfa.form.help_message')}</BannerParagraph>
                          </>
                        </Banner>
                        <Spacer space='xs' direction='horizontal'>
                          <Button mode='ghost' variant='secondary' onClick={handleLoginRedirect}>
                            {t('login.mfa.form.exit')}
                          </Button>
                          <Button
                            mode='outline'
                            variant='primary'
                            data-test='mfa-help-page-link'
                            onClick={() => window.open(t('login.mfa.form.help_page_link'))}
                          >
                            {t('login.mfa.form.help_page')}
                          </Button>
                        </Spacer>
                      </Spacer>
                    )}
                  </Spacer>
                )}
              />
            </Spacer>
          </Spacer>

          <Signup space='xxs' data-test='change-account-option'>
            <Typography color='neutral-400'>{t('login.mfa.change_account')}</Typography>
            <ExternalLink onClick={handleLoginRedirect} data-test='login-link'>
              <Typography color='neutral-600'>{t('login.mfa.log_in')}</Typography>
            </ExternalLink>
          </Signup>
        </FormWrapper>
      </RightWrapper>
    </LayoutWrapper>
  );
}
